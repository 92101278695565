// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-car-lashing-en-js": () => import("./../../../src/pages/car-lashing_en.js" /* webpackChunkName: "component---src-pages-car-lashing-en-js" */),
  "component---src-pages-car-lashing-js": () => import("./../../../src/pages/car-lashing.js" /* webpackChunkName: "component---src-pages-car-lashing-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cum-buckle-js": () => import("./../../../src/pages/cum-buckle.js" /* webpackChunkName: "component---src-pages-cum-buckle-js" */),
  "component---src-pages-ec-solution-division-js": () => import("./../../../src/pages/ec-solution-division.js" /* webpackChunkName: "component---src-pages-ec-solution-division-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-division-js": () => import("./../../../src/pages/iot-division.js" /* webpackChunkName: "component---src-pages-iot-division-js" */),
  "component---src-pages-lashingbelt-js": () => import("./../../../src/pages/lashingbelt.js" /* webpackChunkName: "component---src-pages-lashingbelt-js" */),
  "component---src-pages-logisticsequipment-js": () => import("./../../../src/pages/logisticsequipment.js" /* webpackChunkName: "component---src-pages-logisticsequipment-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-roundsling-js": () => import("./../../../src/pages/roundsling.js" /* webpackChunkName: "component---src-pages-roundsling-js" */),
  "component---src-pages-slingbelt-js": () => import("./../../../src/pages/slingbelt.js" /* webpackChunkName: "component---src-pages-slingbelt-js" */),
  "component---src-pages-software-division-js": () => import("./../../../src/pages/software-division.js" /* webpackChunkName: "component---src-pages-software-division-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */)
}

